<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <b-col md="12">
                        <b>{{ $t('globalTrans.comments') }} : </b> {{ getRatingName(items.rating_id) }}
                    </b-col>
                    <b-col md="12">
                        <b>{{ $t('externalTradeTraiff.feedback') }} : </b> {{ items.comment }}
                    </b-col>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import AddressHelper from '@/utils/area-type-address'
import { authServiceBaseUrl, tradeTariffServiceBaseUrl } from '@/config/api_config'
export default {
    props: ['items'],
    components: {
    },
    created () {
    },
    mounted () {
    },
    data () {
        return {
            authServiceBaseUrl: authServiceBaseUrl,
            AddressHelper: AddressHelper,
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            user: ''
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
          getRatingName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.ratingList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
         }
    }
}

</script>
