<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tradeTariffApplication.feedback_and_rating') }}</h4>
      </template>
    </card>

    <body-card>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <!-- <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" /> -->
          <b-row>
            <b-col md="12" class="table-responsive">
              <table class="table table-hover table-bordered">
                <thead>
                <tr class="bg-primary">
                  <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                  <th scope="col" class="text-center"><div>{{ $t('globalTrans.user') }}</div></th>
                  <th scope="col" class="text-center"><div>{{ $t('globalTrans.mobile_no') }}</div></th>
                  <th style="width: 15%" scope="col" class="text-center"><div>{{ $t('tradeTariffApplication.rating') }}</div></th>
                  <th scope="col" class="text-center"><div>{{ $t('globalTrans.action') }}</div></th>
                </tr>
                </thead>
                <tbody  v-if="showData">
                  <tr v-for="(item, index) in dataItem" :key="index" >
                    <td class="text-center">{{ $n(index+1) }}</td>
                    <td class="text-center" v-if="users.length > 0">{{ getUserName(item.created_by) }}</td>
                    <td class="text-center" v-if="users.length > 0">{{ getMobileNo(item.created_by) | mobileNumber }}</td>
                    <td style="width: 15%" class="text-center">{{ getRatingName(item.rating_id) }}</td>
                    <td class="text-center">
                    <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { authServiceBaseUrl, tradeTariffServiceBaseUrl } from '@/config/api_config'
import Details from './Details.vue'
import { FeedbackAndRatingList } from '../../api/routes'

export default {
  components: {
    Details
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      showData: false,
      loadingState: false,
      dataItem: [],
      viewitemId: 0,
      items: []
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    viewTitle () {
        return this.$t('tradeTariffApplication.feedback_and_rating') + ' ' + this.$t('globalTrans.details')
    }
  },
  methods: {
    getRatingName (id) {
          const obj = this.$store.state.TradeTariffService.commonObj.ratingList.find(item => item.value === parseInt(id))
          if (obj) {
              return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
          } else {
              return ''
          }
    },
    getUserName (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    getMobileNo (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.mobile : user.mobile) : ''
    },
    details (item) {
      this.items = item
    },
    async getForwardedUsers (forwards) {
      this.loadingState = true
      var userIds = [0]
      forwards.map((item, key) => {
        userIds.push(item.created_by)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/externel-user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.users = response
            this.showData = true
            this.loadingState = false
          } else {
            this.users = []
            this.showData = false
            this.loadingState = false
          }
      })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, FeedbackAndRatingList)
      if (result.success) {
        this.getForwardedUsers(result.data)
        this.dataItem = result.data
      } else {
        this.dataItem = ''
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
